import React from 'react';
import styles from './Header.module.css';

const Header = () => {
  return (
    <header className={styles.header}>
      <h2 className={styles.headerTitle}>Welcome to Sanfir</h2>
      <p className={styles.headerSubtitle}>Advocating for Human Rights and Justice Worldwide</p>
    </header>
  );
};

export default Header;
