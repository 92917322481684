import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './Navbar.module.css';

const Navbar = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <nav className={styles.navbar}>
      <h1>Sanfir</h1>
      <button className={styles.mobileMenuButton} onClick={toggleMobileMenu}>
        ☰
      </button>
      <ul className={`${styles.navLinks} ${isMobileMenuOpen ? styles.mobileMenuOpen : ''}`}>
        <li className={styles.navItem}><Link className={styles.navLink} to="/" onClick={toggleMobileMenu}>Home</Link></li>
        <li className={styles.navItem}><Link className={styles.navLink} to="/about" onClick={toggleMobileMenu}>About Us</Link></li>
        <li className={styles.navItem}><Link className={styles.navLink} to="/initiatives" onClick={toggleMobileMenu}>Initiatives</Link></li>
        <li className={styles.navItem}><Link className={styles.navLink} to="/resources" onClick={toggleMobileMenu}>Resources</Link></li>
        <li className={styles.navItem}><Link className={styles.navLink} to="/contact" onClick={toggleMobileMenu}>Contact</Link></li>
      </ul>
    </nav>
  );
};

export default Navbar;
