import React from 'react';
import styles from './Home.module.css';

const Home = () => {
  return (
    <main className={styles.home}>
      <h2 className={styles.homeTitle}>Protecting Rights, Promoting Justice</h2>
      <p className={styles.homeDescription}>
        Sanfir is dedicated to defending human rights, educating communities, and driving policy change.
      </p>
    </main>
  );
};

export default Home;
